.Header {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

@media screen and (min-width: 46.875em) {
  .Header {
    flex-direction: row;
  }
}

.Image {
  margin: auto;
  width: 100%;
  background-color: rgba(255, 192, 203, 0.6);
  border-radius: 10px;
}

.Image img {
  width: 300px;
}

.AppLinkHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
}

@media (max-width: 46.875em) {
  .Image img {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .AppLinkHeader {
    max-width: 50%;
  }
}
