.Main {
  display: flex;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 46.875em) {
  .Main {
    max-width: 1156px;
    flex-direction: row;
  }
}
