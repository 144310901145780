.Background {
  background-image: url(../../../assets/images/background.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 16px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
