.Layout {
  margin-top: 16px;
  margin-bottom: 16px;
}

.AppLinkFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
}

.CompanyName {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  font-weight: bold;
}
