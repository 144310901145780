.ScreenImages img {
  max-width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 16px;
}

@media screen and (min-width: 46.875em) {
  .ScreenImages img {
    margin: 64px;
  }
}
