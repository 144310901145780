.AppInfo {
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 10px;
}

@media screen and (min-width: 46.875em) {
  .AppInfo {
    max-width: 50%;
    margin-right: 16px;
  }
}
