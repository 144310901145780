.Toolbar {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  margin: auto;
}

.Toolbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  float: none;
  background-color: rgba(255, 192, 203, 0.6);
  border-radius: 10px;
}

.Toolbar li {
  display: inline-block;
  margin: 20px;
  left: 0;
  top: 0;
}

.Toolbar a {
  text-decoration: none;
  font-weight: bold;
  color: rgba(235, 96, 142);
}

.Toolbar img {
  height: 100%;
  width: 25%;
}
